import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Heading,
  HStack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { TextInput } from '@laxmimanogna/code-quick-components';
import React, { useContext, useEffect, useState } from 'react';
import { CustomTab } from '../../components/CustomTab';
import NotApprovedPage from '../../components/NotApprovedPage';
import AppColors from '../../constants/AppColors';
import { FONT_FAMILY } from '../../constants/Theme';
import { withProvider } from '../../hoc/withProvider';
import useCQMediaQuery from '../../hooks/useCQMediaQuery';
import { AuthContext } from '../../providers/AuthProvider';
import DashboardProvider, { DashboardContext } from '../../providers/DashboardProvider';
import { getCurrentUserTypeName } from '../../utils/getCurrentUserTypeId';
import ChartActivityTable from './components/ChartActivityTable';
import { useLocation, useNavigate } from 'react-router-dom';

const ChartActivityContainer = props => {
  const authContext = useContext(AuthContext);
  const dashboardContext = useContext(DashboardContext);
  const location = useLocation();
  const navigate = useNavigate();

  const myChartsTab = [
    { title: 'RVU Opportunity', header: 'RVU Opportunity', component: ChartActivityTable, key: 'rvu_percentage' },
    { title: 'Upcoded', header: 'Upcoded', component: ChartActivityTable, key: 'upcoded_percentage' },
    { title: 'Downcoded', header: 'Downcoded', component: ChartActivityTable, key: 'downcoded_percentage' },
    { title: 'Modifier', header: 'Modifier', component: ChartActivityTable, key: 'modifier_percentage' },
  ];

  const { selectedTab: initialSelectedTabKey = 'rvu_percentage' } = location.state || {};
  const initialSelectedTab = myChartsTab.findIndex(tab => tab.key === initialSelectedTabKey);
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab >= 0 ? initialSelectedTab : 0);
  const [onSearchText, setSearchText] = useState('');
  const [name, setName] = useState('');
  const notApprovedPage = !authContext.currentUser.is_approved;

  useEffect(() => {
    const data = getCurrentUserTypeName(authContext.currentUser);
    setName(data);
  }, [authContext.currentUser]);

  useEffect(() => {
    const selectedTabKey = myChartsTab[selectedTab]?.key;
    if (selectedTabKey) {
      navigate(location.pathname, { state: { selectedTab: selectedTabKey }, replace: true });
    }
  }, [selectedTab, navigate, location.pathname]);

  const onSearchAction = () => {
    dashboardContext.getChartActivityRvu.setParams({ page: 1 });
    dashboardContext.setChartFilters({ ...dashboardContext.chartFilters, search: onSearchText });
  };

  const onFilterChange = e => {
    if (e) {
      dashboardContext.getCharts.setParams({ page: 1 });
      setSearchText(e.target.value);
    } else {
      setSearchText('');
    }
  };

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  const marginHorizontal = useCQMediaQuery({
    lg: '12%',
    md: '6%',
    sm: '2%',
  });

  return (
    <>
      <Box mx={marginHorizontal} opacity={notApprovedPage ? '0.1' : 1}>
        <Heading my={5} fontSize={'xl'}>{`Chart Activity Breakdown`}</Heading>
        <Box shadow={'md'} bg="white" p={4} w="100%" style={{ borderRadius: '12px' }}>
          <Tabs fontFamily={FONT_FAMILY.baiJamurjee} onChange={handleTabChange} index={selectedTab}>
            <TabList>
              <HStack w={'100%'} justifyContent={'space-between'}>
                <HStack spacing={4} px={4}>
                  {myChartsTab.map((tab, index) => (
                    <CustomTab
                      key={`my-charts-tab-${index}`}
                      _selected={{ fontWeight: 'bold', color: AppColors.secondary }}
                      color={'black'}
                      fontFamily={FONT_FAMILY.baiJamurjee}
                    >
                      {tab.title}
                    </CustomTab>
                  ))}
                </HStack>
                <Box>
                  <TextInput
                    borderRadius={'md'}
                    value={onSearchText}
                    onChange={onFilterChange}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                    }}
                    mb="2"
                    rightIconProps={{ zIndex: 0 }}
                    placeholder="Search"
                    rightIcon={<SearchIcon onClick={onSearchAction} color={AppColors.gray} />}
                  />
                </Box>
              </HStack>
            </TabList>
            <TabPanels>
              {myChartsTab.map((tab, index) => (
                <TabPanel p={0} key={`my-charts-panel-${index}`}>
                  <tab.component selectedTab={selectedTab} />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
      {notApprovedPage && <NotApprovedPage />}
    </>
  );
};

export default withProvider(DashboardProvider, ChartActivityContainer);
