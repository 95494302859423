import {
    CQReactTable,
} from '@laxmimanogna/code-quick-components';
import React, { useContext, useEffect, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
    Box,
    HStack,
    Text,
    Button,
} from '@chakra-ui/react';
import AppColors from '../../../../constants/AppColors';
import { defaultReactTableProp } from '../../../../utils/my_audits.utils';
import { FONT_FAMILY } from '../../../../constants/Theme';
import InfoTooltip from '../../../../components/InfoTooltip';
import MyAccountProvider, { MyAccountContext } from '../../../../providers/MyAccountProvider';
import { withProvider } from '../../../../hoc/withProvider';
import { useParams } from 'react-router-dom';
const columnHelper = createColumnHelper();

const EducationClassTable = ({ recomCourse, filter, selectedDates, isCLoading }) => {
    const params = useParams();
    const { physicianId } = params;
    const [isRegisteredInMoodle, setIsRegisteredInMoodle] = useState(false);
    const accountsContext = useContext(MyAccountContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const providerData = await accountsContext.getProvider(physicianId);
                if (providerData?.moodle_user_id) {
                    setIsRegisteredInMoodle(true);
                }
            } catch (error) {
                console.error('Error fetching provider data:', error);
            }
        };
        if (physicianId) {
            fetchData();
        }
    }, [physicianId, filter, selectedDates]);

    const data = [
        { course: 'Coding 101', call: 'https://calendly.com/education-sessions/coding-101', link: 'https://revintegrity.moodlecloud.com/login/index.php' },
        { course: 'Documentation 101', call: 'https://calendly.com/education-sessions/documentation-101', link: 'https://revintegrity.moodlecloud.com/login/index.php' },
        { course: 'MDM 101', call: 'https://calendly.com/education-sessions/mdm101', link: 'https://revintegrity.moodlecloud.com/login/index.php' },
    ];

    const columns = React.useMemo(
        () => [
            columnHelper.accessor('courseName', {
                header: () => 'Course',
                id: 'courseName',
                cell: info => {
                    return (
                        <Text fontWeight="bold">
                            {info.renderValue()}
                        </Text>
                    );
                },
            }),
            columnHelper.accessor('enrolled', {
                header: () => 'Enrolled',
                id: 'enrolled',
                cell: info => {
                    return (
                        <Text>
                            {info.renderValue()}
                        </Text>
                    );
                },
            }),
            columnHelper.accessor('progress', {
                header: () => 'Progress',
                id: 'progress',
                cell: info => {
                    const progressStatus = info.renderValue();
                    let textColor;
                    if (progressStatus === "Completed") {
                        textColor = "green";
                    } else if (progressStatus === "In Progress") {
                        textColor = AppColors.secondary;
                    } else {
                        textColor = "inherit";
                    }

                    return (
                        <Text color={textColor}>
                            {progressStatus}
                        </Text>
                    );
                },
            }),
            columnHelper.accessor('url', {
                header: () => '',
                enableSorting: false,
                id: 'view',
                cell: info => {
                    return (
                        <Button
                            size={'sm'}
                            bg={AppColors.secondary}
                            color={'white'}
                            borderRadius="3xl"
                            px={10}
                            fontWeight="normal"
                            fontSize={'sm'}
                            _hover={{
                                transition: 'all .2s ease',
                                bgColor: '#4d75b3',
                                color: AppColors.white,
                            }}
                            onClick={() => window.open(info.renderValue(), '_blank')}
                        >
                            View
                        </Button>
                    );
                },
            }),
        ],
        // eslint-disable-next-line
        []
    );

    const getTableCellProps = (cell, data) => {
        const startingHeaderId = ['courseName',];

        let color = 'black';
        if (data.column.id === 'date' || data.column.id === 'accuracy') {
            color = AppColors.gray;
        }
        return {
            fontFamily: FONT_FAMILY.ptSans,
            color: color,
            textAlign: startingHeaderId.includes(data.column.id) ? 'none' : 'center',

        };
    };

    function getHeaderCellProps(header) {
        const startingHeaderId = ['courseName',];
        return {
            textTransform: 'none',
            fontFamily: FONT_FAMILY.ptSans,
            fontWeight: 'bold',
            color: 'black',
            justifyContent: startingHeaderId.includes(header.id)
                ? 'flex-start'
                : 'center',
        };
    }

    return <>
        <Box
            marginTop={3}
            marginBottom={15}
            width={'100%'}
            bg={AppColors.white}
            padding={4}
            borderRadius={'lg'}
            shadow={'md'}
        >
            <HStack width={'100%'} justifyContent={'space-between'} mb={3}>
                <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
                    Education Class
                </Text>
                <InfoTooltip infoContent={
                    <>
                        <p>
                            <b>Education Class</b> allows providers to sign up for group education sessions or engage with our self-paced education program. These educational resources are designed to enhance your knowledge and skills, improving overall coding accuracy and compliance.
                        </p>
                    </>} />
            </HStack>
            <CQReactTable
                isLoading={isCLoading}
                data={recomCourse}
                columns={columns}
                {...defaultReactTableProp}
                getHeaderCellProps={header => {
                    return {
                        ...getHeaderCellProps(header),
                    };
                }}
                getCellProps={getTableCellProps}

            />
            {!isCLoading && (isRegisteredInMoodle ? (
                <>
                    {/* <Box mt={8} mb={4}>
                        <hr style={{ borderColor: AppColors.lightGray, borderWidth: '1px' }} />
                    </Box>
                    <Box padding={4} borderRadius={'md'} textAlign={'center'}>
                        <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.secondary}>
                            Provider Moodle Account is Created.
                        </Text>
                    </Box> */}
                </>
            ) : (
                <>
                    {/* <Box padding={4} borderRadius={'md'} textAlign={'center'}>
                        <Text fontFamily={FONT_FAMILY.ptSans} color=''>
                            Moodle Account is not created.
                        </Text>
                    </Box> */}
                </>
            ))}
        </Box>
    </>


}
export default withProvider(MyAccountProvider, EducationClassTable);