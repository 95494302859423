import { API } from '../config/AppConfig';
import axios from "axios";
import {
  CREATE_DEPARTMENT,
  CREATE_HOSPITAL,
  DELETE_HOSPITAL,
  EDIT_USER_DATA,
  EDIT_CLIENT_TYPE_LOGO,
  EHR_LIST,
  GET_DEPARTMENT_BY_KEYWORD,
  GET_DEPARTMENT_DROPDOWN,
  GET_DEPARTMENT_DROPDOWN_BY_HOSPITAL,
  GET_PHYSICIAN_SPECIALTIES,
  GET_PROVIDER,
  INSURANCE_LIST,
  MANAGE_DEPARTMENT,
  MOODLE_ACTION,
  PRACTICE_INFO_DEPARTMENT,
  PRACTICE_INFO_HEALTH_SYSTEM,
  PRACTICE_INFO_HOSPITAL,
  PRACTICE_INFO_PROVIDER,
  UPDATE_DEPARTMENT_PRACTICE_INFO,
  UPDATE_HEALTH_SYSTEM_PRACTICE_INFO,
  UPDATE_HOSPITAL_PRACTICE_INFO,
  USER_TYPE_LOOKUP,
  GET_COURSE_RECOM,
} from '../constants/Endpoints';
import { ACCESS_TOKEN } from "../constants/PreferenceKeys";

class MyAccountRepository {
  async userTypeLookup() {
    const response = await API.get(USER_TYPE_LOOKUP);
    return response;
  }
  async editUserData(userBody) {
    const response = await API.put(EDIT_USER_DATA, {
      body: userBody,
    });
    return response;
  }

  async editClientTypeLogo(formData) {
    const token = localStorage.getItem(ACCESS_TOKEN)
    const response = await axios.post(EDIT_CLIENT_TYPE_LOGO,
                                      formData,
                                      {
                                        headers: {
                                          Authorization: token,
                                        },
                                      },
                                    );
    return response;
  }

  async getEHROptions() {
    const response = await API.get(EHR_LIST);
    return response;
  }
  async getInsuranceOptions() {
    const response = await API.get(INSURANCE_LIST);
    return response;
  }
  async practiceInfoHealthSystem(health_system_id) {
    const response = await API.get(PRACTICE_INFO_HEALTH_SYSTEM, {
      queryParams: { health_system_id },
    });
    return response[0];
  }
  async praticeInfoHospital(hospital_id) {
    const response = await API.get(PRACTICE_INFO_HOSPITAL, {
      queryParams: { hospital_id },
    });
    return response[0];
  }
  async praticeInfoPhysicianGroup(health_system_id) {
    const response = await API.get(PRACTICE_INFO_HEALTH_SYSTEM, {
      queryParams: { health_system_id },
    });
    return response[0];
  }
  async praticeInfoDepartment(department_id) {
    const response = await API.get(PRACTICE_INFO_DEPARTMENT, {
      queryParams: { department_id },
    });
    return response[0];
  }
  async practiceInfoProvider(provider_id) {
    const response = await API.get(PRACTICE_INFO_PROVIDER, {
      queryParams: { provider_id },
    });
    return response[0];
  }

  async getSpecialtyOptions(queryParams) {
    const response = await API.get(GET_PHYSICIAN_SPECIALTIES, {
      queryParams
    });
    return response.map(res => ({
      label: res.name,
      value: res.id ? res.id : res.name,
      id: res.id,
    }));
  }

  async createHospital(hospitalData) {
    const { health_system, ...hospitalCreateData } = hospitalData;
    const response = await API.post(CREATE_HOSPITAL, {
      body: hospitalCreateData,
      pathParams: { health_system },
    });
    return response;
  }

  async getProvider(providerId) {
    const response = await API.get(GET_PROVIDER, {
      pathParams: { providerId },
    });
    return response;
  }

  async getRecomCourse(providerId, queryParams) {
    const response = await API.get(GET_COURSE_RECOM, {
      pathParams: { providerId },
      queryParams,
    });
    return response;
  }

  async getDepartmentDropdown(hospitalId) {
    const response = await API.get(GET_DEPARTMENT_DROPDOWN, {
      pathParams: { hospitalId },
    });
    return response;
  }

  async createDepartment(hospitalId, payload) {
    const response = await API.post(CREATE_DEPARTMENT, {
      pathParams: { hospitalId },
      body: payload,
    });
    return response;
  }

  async getDepartmentByKeyword(keyword) {
    const response = await API.get(GET_DEPARTMENT_BY_KEYWORD, {
      queryParams: { search: keyword },
    });
    return response;
  }

  async updateHealthSystemPracticeInfo(body) {
    const { id, ...updatedBody } = body;
    const response = await API.post(UPDATE_HEALTH_SYSTEM_PRACTICE_INFO, {
      queryParams: { health_system_id: id },
      body: updatedBody,
    });
    return response;
  }

  async updateHospitalPracticeInfo(body) {
    const { id, ...updatedBody } = body;
    const response = await API.post(UPDATE_HOSPITAL_PRACTICE_INFO, {
      queryParams: { hospital_id: id },
      body: updatedBody,
    });
    return response;
  }
  async updateDepartmentPracticeInfo(body) {
    const { id, ...updatedBody } = body;
    const response = await API.post(UPDATE_DEPARTMENT_PRACTICE_INFO, {
      queryParams: { department_id: id },
      body: updatedBody,
    });
    return response;
  }
  async deleteDepartment(id) {
    const response = await API.delete(MANAGE_DEPARTMENT, {
      pathParams: { id: id },
    });
    return response;
  }
  async deleteHospital(id) {
    const response = await API.delete(DELETE_HOSPITAL, {
      pathParams: { id: id },
    });
    return response;
  }
  async getDepartmentByHospital(hospital_id) {
    const response = await API.get(GET_DEPARTMENT_DROPDOWN_BY_HOSPITAL, {
      queryParams:  hospital_id ,
    });
    return response;
  }
}

const myAccountRepository = new MyAccountRepository();
export default myAccountRepository;
